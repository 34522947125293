.header {
  background-color: transparent;
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  height: 76px;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo-text {
    margin-left: 8px;
  }

  .button-menu {
    background-color: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  &__right {
    .nav-item {
      color: $color_white;
      font-weight: 800;
      margin-right: 32px;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .header {
    &__right {
      display: flex;
      align-items: center;
    }
  }

  .drawer {
    .header {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-drawer-content {
      background-image: url(../resources/images/background-mobile.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .ant-drawer-body {
      padding: 0;
      display: flex;
      flex-flow: wrap column;
      justify-content: space-between;
    }

    .header {
      position: static;
      justify-content: space-between;
    }

    .body {
      text-align: center;

      .ant-btn,
      .nav-item {
        width: calc(100% - 40px);
        margin-top: 1em;
      }

      .nav-item {
        display: block;
        margin: auto;
        color: $color_white;
        font-weight: 800;

        &:not(:first-child) {
          margin-top: 1em;
        }
      }
    }

    &__footer {
      margin-bottom: 1em;

      .social {
        padding-left: 0;
        display: flex;
        justify-content: center;
        list-style: none;

        &__icon:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}
