@import 'variables';
@import 'overrides';
@import 'common';
@import 'layout';

/* Components */
@import 'header';
@import 'footer';
@import 'components';

/* Pages */
@import 'home.scss';
@import 'howItWorks.scss';

html,
body {
  font-family: 'Montserrat', sans-serif;
}
