html,
body {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;

  h4,
  h5,
  ul,
  p {
    margin-bottom: 0;
  }
}

.container {
  max-width: 1170px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.highlight {
  color: $color_anakiwa !important;
}

.button-connected {
  color: $color_white;
  background-color: transparent;
  border: 3px solid $color_primary;
  font-size: 16px;
  font-weight: 800;
  height: 40px;

  &:hover,
  &:focus {
    color: $color_white;
    background-color: transparent;
    border-color: $color_primary;
    opacity: 0.8;
  }
}
