.ant-btn-primary {
  @include background-primary;
  height: 40px;

  border: none;
  border-radius: 3px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;

  &:focus,
  &:active,
  &:hover {
    @include background-primary;

    opacity: 0.8;
  }
}

.ant-popover {
  &-arrow {
    display: none;
  }

  &-inner {
    box-shadow: 0px 16px 64px rgba(31, 47, 70, 0.2);
    border-radius: 5px;
  }

  &-inner-content {
    padding: 0;

    .button-disconnect {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.walletconnect-modal__mobile__toggle {
  display: none !important;
}
