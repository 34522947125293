.title {
  color: $color_white;
  text-shadow: 4px 0px 0px $color_heliotrope;
  font-size: 72px;
  font-weight: 600;
  line-height: 88px;
  padding-left: 12px;
}

.ant-layout {
  .social {
    list-style: none;
    position: fixed;
    padding-left: 0;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    z-index: 2;

    &__icon {
      &:not(:first-child) {
        margin-top: 12px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.statistic {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;

  &__title {
    color: $color_athens_gray;
    font-weight: 600;
  }

  &__value {
    line-height: 24px;
    font-weight: 600;
    color: $color_white;
  }

  .ant-progress-steps-item {
    width: 10px !important;
    height: 18px !important;
    margin-right: 4px;
  }
}

.wrong-network-modal {
  .ant-modal-content {
    background-color: $color_tangaroa;
    border-radius: 10px;
  }

  .ant-modal-body {
    padding-top: 64px;
    padding-bottom: 60px;
    text-align: center;
  }

  .anticon-spin {
    font-size: 80px !important;

    path {
      fill: $color_primary;
    }
  }

  .title {
    margin-top: 34px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color_primary;
  }

  .description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $color_anakiwa;
  }

  .ant-space {
    .ant-btn {
      width: 100%;
    }
  }
}

.connect-wallet-modal {
  .ant-modal-content {
    background-color: $color_tangaroa;
    border-radius: 10px;
  }

  .ant-modal-body {
    padding-top: 64px;
    padding-bottom: 60px;
    text-align: center;
  }

  .anticon-spin {
    font-size: 80px !important;

    path {
      fill: $color_primary;
    }
  }

  .ant-modal-close-icon {
    path {
      fill: $color_white;
    }
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color_primary;
    margin-bottom: 0;
  }

  .description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $color_anakiwa;
  }

  .ant-space {
    margin-top: 32px;
    width: 100%;
    max-width: 230px;

    .ant-btn {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      font-weight: 500;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon__left {
        width: 30px;
        height: auto;
        margin-right: 14px;
      }

      span {
        flex: 1;
      }
    }
  }
}

.round {
  background-color: $color_tangaroa;
  overflow: hidden;
  height: 100%;

  &__title {
    background-color: $color_black_rock;
    padding: 15px 45px 15px;
    color: $color_porcelain;
    text-shadow: 2px 0px 0px $color_heliotrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .ant-row {
    padding: 34px 45px 36px;
    margin-top: 0;
  }

  .ant-progress-steps-item {
    width: 6px !important;
    height: 10px !important;
    margin-right: 2px;
  }
}

.big-icon {
  padding: 0.5em;
  border: 2px solid $color_bright_turquoise;
  background-color: transparent;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  overflow: hidden;

  &__container {
    display: flex;
    flex-flow: wrap column;
    align-items: center;
    justify-content: center;
    background-color: $color_tangaroa;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap column;
  }

  &__title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    color: $color_white;
    text-shadow: 2px 0px 0px $color_heliotrope;
    text-align: center;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: $color_alto;
    text-align: center;
    max-width: 150px;
  }
}

.box-item {
  color: $color_alto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 24px;
  background: conic-gradient(
    from 180deg at 50% 0%,
    #1d0070 0deg,
    #1c006d 135deg,
    #260080 240deg,
    #210967 337.5deg,
    #1d0070 360deg
  );
  box-shadow: 4px 4px 0px #00fbff;
}

@media (min-width: $breakpoint_mobile_max) {
  .round__bottom {
    min-height: 85px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .box-item {
    font-size: 12px;
    line-height: 20px;
    padding: 12px;
  }

  .big-icon {
    width: 100%;
    height: 100%;
    border: none;
    overflow: unset;

    &__container {
      background-color: transparent;
      flex-flow: row;
      justify-content: flex-start;
    }

    &__image {
      margin-right: 1em;
      width: 60px;
      height: 60px;
      object-fit: contain;
      background-color: $color_tangaroa;
      border: 3px solid $color_bright_turquoise;
      border-radius: 50%;
      padding: 0.4em;
    }

    &__content {
      align-items: flex-start;
    }

    &__title {
      text-align: left;
    }

    &__subtitle {
      max-width: 100%;
      text-align: left;
    }
  }
}
