.home {
  background-color: $color_tangaroa;
  background-image: url(../resources/images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 100vh;
  background-attachment: fixed;

  .banner {
    display: flex;
    align-items: center;
    padding-top: 210px;
    padding-bottom: 120px;

    .container {
      max-width: 1440px;
    }

    .title {
      color: $color_white;
      text-shadow: 4px 0px 0px $color_heliotrope;
      font-size: 72px;
      font-weight: 600;
      line-height: 88px;
      padding-left: 12px;
    }

    .subtitle {
      color: $color_white;
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      text-shadow: 2px 0px 0px $color_heliotrope;
      padding-left: 12px;
      margin-top: 30px;
      margin-bottom: 28px;
    }

    .statistic__title {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
    }

    .ant-progress {
      margin-top: 16px;
    }
  }

  .banner-guest {
    flex-flow: wrap column;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 100%;

    .title {
      padding-left: 0;
    }

    .description {
      color: $color_anakiwa;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-top: 24px;
    }

    .ant-btn-primary {
      margin-top: 44px;
      height: 60px;
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 12px;
    }
  }

  .button-claim {
    height: 32px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 12px;

    &[disabled] {
      background-color: $color_cadet_blue;
      color: $color_white;
    }
  }

  .section-details {
    padding-bottom: 90px;

    .background-white {
      padding-top: 48px;
      padding-bottom: 48px;
      max-width: calc(100% - 70px);
      width: 100%;
      background-color: rgba(232, 230, 230, 0.7);
      margin: 0 auto;
    }

    .title {
      text-shadow: 2px 0px 0px $color_heliotrope;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.01em;
      color: $color_black_rock;
      margin-bottom: 14px;
    }

    .list-round {
      margin-top: 28px;
    }

    .switch {
      .ant-switch {
        background-color: $color_waterloo;
      }

      .ant-switch-checked {
        background-color: $color_tangaroa;

        .ant-switch-handle::before {
          background-color: $color_anakiwa;
        }
      }

      &__label {
        line-height: 24px;
        font-weight: 600;
        color: $color_woodsmoke;
      }
    }

    .countdown {
      text-align: center;
      color: $color_white;
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: center;

      .time {
        &:not(:last-child) {
          margin-right: 8px;
        }

        width: 85px;
        height: 85px;
        border: 2px solid $color_anakiwa;
        border-radius: 16px;
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        justify-content: center;
        font-weight: 700;

        .value {
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.02em;
          color: $color_white;
        }

        .unit {
          font-size: 12px;
          line-height: 16px;
          color: $color_primary;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_ultra_desktop_max) {
  .home {
    .banner .container {
      max-width: 1280px;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .social {
    display: none;
  }

  .home {
    .banner {
      .title {
        font-size: 40px;
        line-height: 49px;
        text-align: center;
      }

      .subtitle {
        font-size: 24px;
        line-height: 29px;
        text-align: center;
      }

      .statistic {
        justify-content: center;
        column-gap: 8px;

        img {
          width: 80px;
          height: auto;
        }

        &__title {
          font-size: 16px;
          line-height: 20px;
        }

        .ant-progress-steps-item {
          width: 8px !important;
          height: 16px !important;
        }
      }
    }

    .banner-guest {
      .title {
        font-size: 28px;
      }

      .description {
        font-size: 14px;
        margin-top: 16px;
      }

      .ant-btn-primary {
        margin-top: 24px;
        height: 40px;
        font-size: 14px;
      }
    }

    .section-details {
      .background-white {
        max-width: 100%;
      }

      .title {
        font-size: 24px;
        line-height: 29px;
        margin-left: 45px;
      }

      .switch {
        margin-left: 45px;
      }

      .countdown {
        .time {
          width: 64px;
          height: 64px;
        }
      }

      .round {
        &__title {
          padding: 15px 30px;
        }

        .ant-row {
          padding: 20px 24px 36px;
        }

        .statistic {
          justify-content: flex-start;
          column-gap: 8px;
        }
      }
    }
  }
}
