.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // width: 100%;
  // z-index: 1;
  border-top: 1px solid $color_blue_ribbon;

  .container {
    text-align: center;
  }

  .copyright {
    text-transform: uppercase;
    font-size: 12px;
    color: $color_white;
    font-weight: 700;
    padding: 8px 0;
    display: inline-block;
  }
}
