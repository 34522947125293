.how-it-works {
  background-color: $color_tangaroa;
  background-image: url(../resources/images/background-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 100vh;
  background-attachment: fixed;
  padding-bottom: 100px;

  .title {
    padding-top: 180px;
    text-align: center;
  }

  .ant-row {
    align-items: center;
    margin-top: 100px;

    .box-item:not(:first-child) {
      margin-top: 1em;
    }
  }

  .section-claim {
    .big-icon__image {
      width: 137px;
      height: auto;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .how-it-works {
    .title {
      font-size: 40px;
      line-height: 49px;
      padding-top: 150px;
    }

    .ant-row {
      margin-top: 32px;
    }
  }
}
